<template>
  <div class="main-content" v-if="showMain">
    <!-- <div class="main-content"> -->
    <Intro />
    <About />
    <Experience />
    <Projects />
    <Contact />
    <!-- <div class="footer">
      <p>Designed & Built by Paul Roach <br />Using Vue.js</p>
    </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import Intro from "../components/Intro.vue";
import About from "../components/About.vue";
import Experience from "../components/Experience.vue";
import Contact from "../components/Contact.vue";
import Projects from "../components/Projects.vue";
export default {
  name: "Main",
  components: {
    Intro,
    About,
    Experience,
    Contact,
    Projects,
  },
  data() {
    return {
      showMain: false,
    };
  },
  mounted: function () {
    setTimeout(() => (this.showMain = true), 500);
  },
};
</script>

<style scoped>
.main-content {
  animation: fadeIn ease-in-out 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.footer {
  margin: 40px 0px 10px;
}
.main-content {
  position: fixed;
  top: 75px;
  width: 100%;
  display: block;
  overflow-y: auto;
  height: calc(100vh - 75px);
  z-index: 1;
}
</style>