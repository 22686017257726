<template>
  <section id="intro">
    <h4>Hello, there! I'm</h4>
    <h1>Paul Roach.</h1>
    <h2>I have a few ideas.</h2>
    <div class="section-content">
      <p>
        I'm an ambitious designer, creator, maker, software and computer
        engineer set on creating simple and creative solutions to complex
        problems. Lately, I am developing autonomous robotic soil sampling
        systems for golf course turf management at
        <a class="paraLink" href="#experience">Land Maverick.</a>
      </p>
      <div class="rowbreak"></div>
      <!-- <a href="../assets/PaulHeadshotSquare.png" download>
        <button>Resume</button>
      </a> -->
    </div>
  </section>
</template>

<style scoped>
p {
  /* text-align: justify; */
  margin: 10px 0 50px;
  line-height: 24px;
}
</style>