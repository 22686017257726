<template>
  <section id="projects">
    <div class="section-heading">
      <!-- <h4>[2]</h4> -->
      <h3>Projects</h3>
    </div>
    <div class="section-subheading">
      <h4 class="sub">Here's some of my work</h4>
    </div>
    <div class="section-content">
      <ProjectList />
    </div>
    <!-- <button>See More</button> -->
  </section>
</template>

<script>
import ProjectList from "./ProjectList.vue";
export default {
  name: "Projects",
  components: {
    ProjectList,
  },
};
</script>

<style scoped>
h4.sub {
  color: #c0c0c0;
}
</style>