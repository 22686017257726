<template>
  <section id="about">
    <div class="section-heading">
      <!-- <h4>[0]</h4> -->
      <h3>About Me</h3>
    </div>
    <div class="section-content">
      <img style="max-height: 100px" src="../assets/PaulHeadshotSquare.png" />
      <p>
        Hi! I'm Paul, and I enjoy building interactive human-computer
        experiences. For as long as I can remember, I've been inspired by our
        ability to invent and program computers and robots that solve real world
        problems. <br />
        <!-- <a href="https://www.firstinspires.org" target="_blank">First</a>
        "First Lego League" in fourth grade. -->
        <a
          href="https://drive.google.com/file/d/1bwzPdemkGI6Ozt-0paufam4WYOW2mA7h/view?usp=sharing"
          target="_blank"
        >
          <button>View Resume</button>
        </a>
      </p>
    </div>
  </section>
</template>


<style scoped>
p {
  max-width: 460px;
  line-height: 24px;
}
img {
  width: 200px;
  min-height: 200px;
  margin: 20px;
  border-radius: 50%;
  transition: all 0.1s ease-in;
  box-shadow: 0 0 20px #ce00ce;
}
img:hover {
  border-radius: 40%;
  box-shadow: 0 0 20px #00d3b7;
}
button {
  margin: 20px;
}
</style>