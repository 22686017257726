<template>
  <section id="contact">
    <div class="section-heading">
      <!-- <h4>[3]</h4> -->
      <h3>Contact</h3>
    </div>
    <div class="section-subheading">
      <h3>Let's Get In Touch.</h3>
    </div>
    <div class="section-content">
      <p class="bolder">
        I would love to see how my experience could help your team! I encourage
        you to reach out if you want to learn more about me.
      </p>
      <p>
        Whether you have a question or just want to say hi, my inbox is always
        open.
      </p>
      <ul>
        <li>(978)-269-4875</li>
        <li>paultroach@gmail.com</li>
      </ul>
      <div class="rowbreak"></div>
      <a href="mailto:paultroach@gmail.com"><button>Email Me</button></a>
      <a href="https://www.linkedin.com/in/paultroach/" target="_none">
        <button>LinkedIn</button>
      </a>
      <a
        href="https://drive.google.com/file/d/1bwzPdemkGI6Ozt-0paufam4WYOW2mA7h/view?usp=sharing"
        target="_blank"
      >
        <button>Resume</button>
      </a>
    </div>
    <div class="footer">
      <p>Designed & Built by Paul Roach <br />Using Vue.js</p>
    </div>
  </section>
</template>

<style scoped>
.footer {
  margin: 50px 0;
}
.footer p {
  margin-top: 0;
}
p {
  margin-top: 10px;
}
p.bolder {
  color: #ce00ce;
  font-weight: bold;
}
ul {
  margin: 10px;
  color: #c0c0c0;
  font-size: 18px;
  list-style: none;
}
ul li {
  padding: 5px;
}
button {
  margin: 10px;
}
</style>