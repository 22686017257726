<template>
  <section id="experience">
    <div class="section-heading">
      <!-- <h4>[1]</h4> -->
      <h3>Experience</h3>
    </div>
    <div class="section-subheading">
      <h3>Professional Work</h3>
    </div>
    <div class="section-content">
      <div class="tabs">
        <label
          v-for="job in jobs"
          :key="job.name"
          @click="selectJob(job.number)"
          :class="{ active: currentTab === job.number }"
        >
          {{ job.name }}
        </label>
      </div>

      <div
        class="picker-content notVisible"
        v-for="job in jobs"
        :key="job.name"
        :class="{ visible: currentTab === job.number }"
      >
        <h5>{{ job.jobTitle }}</h5>
        <h6>
          <a :href="job.jobSiteLink" target="_none">@ {{ job.name }}</a>
        </h6>
        <p>{{ job.dates }}</p>
        <ul class="job-content">
          <li v-for="point in job.points" :key="point">
            <div class="job-point">
              <i class="material-icons job-icon">chevron_right</i>
              {{ point }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Experience",
  data() {
    return {
      currentTab: 0,
      jobs: [
        {
          name: "Land Maverick",
          number: 0,
          jobTitle: "Robotic Systems Engineer",
          jobSiteLink: "http://www.land-maverick.com/",
          dates: "November 2020 - Present",
          points: [
            "Leading a team designing autonomous navigation systems for mobile outdoor soil nutrient sampling robots",
            "Developed autonomous navigation and optical-based obstacle avoidance algorithms for outdoor turf soil sampling robots using SLAM ToF depth cameras, OpenCV, C++ on Unix-based embedded SoC systems",
            "Researched and developed automatic quality control testing system for soil nutrient sensors to reduce human interaction by 95%",
            "Integrated brushless motor controllers with self-built movement and positioning programs",
          ],
        },
        {
          name: "DLL Solutions",
          number: 1,
          jobTitle: "Software Development Intern",
          jobSiteLink: "http://www.dllsolutions.com/",
          dates: "May 2019 - August 2020",
          points: [
            "Led a project to retrofit 60 industrial fermentation tank temperature controllers with IoT gateways for data collection and remote visualization",
            "Assisted in management of data historian applications for nationwide utility and power generation industries using OSIsoft PI, Azure and AWS",
          ],
        },
        {
          name: "Ipswich Ale",
          number: 2,
          jobTitle: "Automated Packaging Machine Operator",
          jobSiteLink: "https://www.ipswichalebrewery.com/",
          dates: "May 2017-August 2018",
          points: [
            "Installed new automated packing machinery and provided start-up support and diagnostics",
            "Assisted floor engineers in diagnosing and repairing many issues with packing and filling machines",
            "Optimized workflow settings on canning machinery allowing fewer operating hours and costs",
          ],
        },
      ],
    };
  },
  methods: {
    selectJob(jobNumber) {
      console.log(jobNumber);
      this.currentTab = jobNumber;
    },
  },
};
</script>

<style scoped>
.job-point {
  display: flex;
  flex-flow: row nowrap;
}
.job-icon {
  min-width: 40px;
  height: 40px;
}
.notVisible {
  display: none;
}
.visible {
  animation: fadeIn 0.5s ease-in;
  display: block;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
div.tabs {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
}
label {
  margin: auto;
  color: #a0a0a0;
  font-size: clamp(1rem, 1.5vw, 1.25rem);
  padding: 5px;
  border-bottom: 1px solid #a0a0a0;
  transition: all 0.1s ease-in;
  cursor: pointer;
}
label:hover {
  border-bottom: 3px solid #a0a0a0;
  padding-bottom: 3px;
}
label.active {
  color: #ce00ce;
  border-bottom: 3px solid #ce00ce;
  padding-bottom: 3px;
}
.picker-content {
  /* background-color: aqua; */
  text-align: left;
}
p {
  margin-top: 5px;
  font-size: 15px;
}
ul.job-content {
  margin-top: 15px;
  list-style: none;
  color: white;
}
ul.job-content li {
  padding: 5px 0;
}

@media only screen and (min-width: 800px) {
}
</style>