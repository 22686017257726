<template>
  <div class="project-wrapper">
    <div
      class="project"
      v-for="project in projects"
      :key="project.name"
      :class="project.direction"
    >
      <div v-if="project.direction === 'right'" class="project-image">
        <img :src="require('../assets/' + project.image)" />
      </div>
      <div class="project-contents" :class="project.direction">
        <h6>Featured Project</h6>
        <h4>{{ project.title }}</h4>
        <div class="project-description">
          {{ project.text }}
        </div>
        <ul class="tool-list">
          <li v-for="tool in project.toolList" :key="tool">{{ tool }}</li>
        </ul>
        <a
          :href="project.projectLink"
          target="_none"
          v-if="project.projectLink != ''"
          ><button>Read More</button></a
        >
      </div>
      <div v-if="project.direction === 'left'" class="project-image">
        <img :src="require('../assets/' + project.image)" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectList",
  data() {
    return {
      projects: [
        {
          title: "3D Printed Quadrupedal Robot",
          image: "RobotActuator.jpg",
          text: "An autonomous legged robot companion inspired by the Boston Dynamics Spot and MIT Mini Cheetah projects. Self-designed strong, high-precision, 3D printed joint actuators independently controlled by real-time inverse-kinematics and collision-avoidance models.",
          direction: "right",
          toolList: ["3D Printing", "Fusion 360", "C/C++", "Python", "Godot"],
          projectLink: "",
        },
        {
          title: "Food Nutrition Detecting App",
          image: "FoodCameraPic.png",
          text: "A program that determines and displays accurate nutrition information of plated food, including calories, fats, and carbohydrates. Leveraging the power of convolutional neural networks, this program independtly detects what type of foods are on the plate, where on the plate those foods are, and how much of it there is.",
          direction: "left",
          toolList: ["Machine Learning", "Image Processing", "Matlab"],
          projectLink: "",
        },
        {
          title: "Passive-Acoustic Microphone Array",
          image: "Beamforming.png",
          text: "An 8-channel microphone array designed to monitor an outdoor environment. A trained model classifies sounds recorded from the device, specifically detecting and labeling several species of New England birds. An accompanying beamforming algorithm detects the relative direction of arrival of the sound source in the environment. This team project achieved second place in our senior design competition.",
          direction: "right",
          toolList: [
            "Matlab",
            "Python",
            "C++",
            "Raspberry Pi",
            "Audio DAC/ADC",
          ],
          projectLink:
            "https://seniordesignday.engr.uconn.edu/senior-design-by-year/2021-senior-design-day/judging-and-awards-2021/#:~:text=Sponsor%3A%20Presco%20Engineering-,Second%20Place,-%3A%20Team%2027%3A%20Elizabeth",
        },
      ],
    };
  },
};
</script>

<style scoped>
div.project-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.project {
  width: 100%;
  /* background-color: maroon; */
  /* margin: 75px 0; */
  padding: 75px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px dashed #c0c0c0;
}
div img {
  width: 100%;
  border-radius: 50%;
}
.project-image {
  max-width: 250px;
  padding: 20px;
}
.project-contents {
  width: 60%;
}
.project-description {
  background-color: #1d0058;
  padding: 20px;
  margin: 20px 0;
  line-height: 20px;
}
ul.tool-list {
  width: 100%;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 20px 0;
}
ul.tool-list li {
  float: left;
  padding: 0 5px;
}
h4 {
  color: #c0c0c0;
}
.left {
  text-align: start;
  flex-wrap: wrap;
}
.right {
  text-align: end;
  flex-wrap: wrap-reverse;
}
@media only screen and (max-width: 800px) {
  div.project {
    justify-content: center;
  }
  .project-contents {
    width: 100%;
  }
}
</style>