<template>
  <header v-if="showHeader">
    <div id="logo"><a href="#intro">{pr}</a></div>
    <nav>
      <ul>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#contact">Contact</a></li>
        <li id="resume">
          <a
            href="https://drive.google.com/file/d/1bwzPdemkGI6Ozt-0paufam4WYOW2mA7h/view?usp=sharing"
            target="_blank"
          >
            <button>Resume</button>
          </a>
        </li>
      </ul>
    </nav>
    <div id="mobile-menu" v-on:click="menuVisible = !menuVisible">
      <i class="material-icons custom">menu</i>
      <ul class="mobile-menu" v-if="menuVisible">
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#projects">Projects</a></li>
        <li><a href="#contact">Contact</a></li>
        <li id="resume">
          <a
            href="https://drive.google.com/file/d/1bwzPdemkGI6Ozt-0paufam4WYOW2mA7h/view?usp=sharing"
            target="_blank"
          >
            <button>Resume</button>
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      menuVisible: false,
      showHeader: false,
    };
  },
  mounted: function () {
    setTimeout(() => (this.showHeader = true), 500);
  },
};
</script>

<style scoped>
header {
  animation: fadeIn ease-in-out 1.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.material-icons.custom {
  color: #ce00ce;
  cursor: pointer;
  transition: all 0.1s ease-in;
}
.material-icons.custom:hover {
  color: #00d3b7;
}
header {
  padding: 0 20px;
  height: 75px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
header a {
  text-decoration: none;
}

#logo {
  font-family: "Ubuntu Mono", monospace;
  font-weight: bold;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

ul {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
}

ul li {
  padding: 5px;
  margin-left: 10px;
}

#mobile-menu {
  margin: auto 0;
  display: none;
  cursor: pointer;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #100030;
  z-index: 9999;
}

@media only screen and (max-width: 800px) {
  header nav {
    display: none;
  }

  #mobile-menu {
    display: block;
  }
  ul li {
    padding: 20px;
    border-top: 1px solid #c0c0c0;
    width: 100%;
  }
}
</style>